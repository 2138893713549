import React from 'react';
import sizes from 'react-sizes';
import styled, { css } from 'styled-components';
import { SEO, Layout, ContentBody, ContentAside, ResponsiveVideo, MeetTheTeam } from 'components';
import { HeroWrapper, HeroText, HeroImg, ContentWrapper } from 'templates/service.js';
import { FullWidthImage } from 'components/Shared/ContentBody';
import { graphql } from 'gatsby';

const AboutUs = ({ data, location, viewportWidth }) => {
  const { dublinCity, staffPic, services } = data;
  const lineBeneathPhoto =
    viewportWidth < 740
      ? 'Trusted Corporate Events & Video Specialists'
      : 'Trusted Corporate Events<br /> & Video Specialists';
  return (
    <Layout>
      <SEO
        pageSpecificTitle="About Us"
        pageSpecificDescription="Founded in 2014, Get Broadcasting is a leader in video communications, creation, and creativity."
        pageSpecificThumbnail={staffPic.childImageSharp.original.src}
        pathname={location.pathname}
      />
      <HeroWrapper>
        <HeroImg
          fluid={dublinCity.childImageSharp.fluid}
          style={{ width: '100%', height: 600 }}
          alt="Dublin City"
        />
        <HeroText>
          <h1>About Us</h1>
        </HeroText>
      </HeroWrapper>
      <ContentWrapper>
        <ContentBody closingLine="Get communicating. Get creative. Get Broadcasting." slug="">
          {lineBeneathPhoto.split('<br />').map((text, i, arr) => (
            <h2 style={{ fontSize: '3rem' }}>
              {text}
              {viewportWidth > 740 && <MultiColouredBottomBorder />}
              {i + 1 === arr.length && <div style={{ height: '60px' }} />}
            </h2>
          ))}
          <TextWrapper>
            <p>At Get Broadcasting, client satisfaction isn&apos;t just a goal; it's our commitment.</p>
            <p>
              With a decade of excellence, we&apos;re proud to continue shaping powerful narratives for our clients. We stand as an industry front-runner through our <b>innovative BaaS model</b> (Broadcast as a Service), where we specialise in producing live productions with creative digital content. Spanning from Live Events In-Person to Hybrid and Virtual, to Webinars and Conferences, from crafting customised Video and Animation projects to evergreen content to last a decade. Our expertise extends to Digital Content Strategy, Creative Design, Custom 3D Virtual Studios and Audio Engineering.<br /><br /><b>For over 10 years</b>, we offer our services to several of the biggest and best companies in Ireland – including Aviva, Zendesk, Novartis, Pfizer, Flutter, Musgrave and Trinity College Dublin. We have also worked with a great variety of speakers, presenters, and contributors across the world. Additionally, we are the proud Video Partner of the IRFU Charitable Trust.<br /><br />At Get Broadcasting, we&apos;re more than just content creators; <b>we&apos;re storytellers, innovators, and partners in your digital journey</b>.
            </p>
          </TextWrapper>
          <MeetTheTeam />
        </ContentBody>
        <ContentAside otherServices={services.nodes} slug="about-us" />
      </ContentWrapper>
    </Layout>
  );
};

const TextWrapper = styled.div`
  p:first-of-type {
    font-size: 2.4rem;
    margin-bottom: 0.85em;
  }
`;

const MultiColouredBottomBorder = styled.div`
  background: linear-gradient(269.94deg, #e4097e 63.91%, #ffef00 100%);
  height: 3px;
  bottom: 2px;
  position: relative;
`;

export const query = graphql`
  query {
    dublinCity: file(relativePath: { eq: "AboutUs/dublin-city.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    staffPic: file(relativePath: { eq: "AboutUs/staff-pic.png" }) {
      childImageSharp {
        original {
          src
        }
        fluid(maxWidth: 820, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    services: allSanityService {
      nodes {
        id
        title
        extendedTitle
        mainImage {
          image {
            asset {
              fixed(width: 325, height: 198) {
                ...GatsbySanityImageFixed
              }
            }
          }
          title
          alt
        }
        slug {
          current
        }
      }
    }
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(AboutUs);
