import React from 'react';
import styled from 'styled-components';
import { SEO, Layout, ContentBody, ContentAside } from 'components';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import LogoSVG from 'images/Shared/Header/get-broadcasting-logo.svg';
import LivestreamSVG from 'images/Shared/Services/02_livestream_icon.svg';
import TrainingSVG from 'images/Shared/Services/02_training_icon.svg';
import VideoSVG from 'images/Shared/Services/02_video_icon.svg';
import StrategySVG from 'images/Shared/Services/02_strategy_icon.svg';
import sizes from 'react-sizes';

const Service = ({ data, location, viewportWidth }) => {
  const { service, otherServices, trainingFooterImage } = data;
  console.log('Width: ', viewportWidth);
  const {
    title,
    extendedTitle,
    summary,
    mainImage,
    vimeoVideo,
    openingLine,
    closingLine,
    slug,
    _rawBody
  } = service;

  return (
    <Layout>
      <SEO
        pageSpecificTitle={title}
        pageSpecificDescription={summary}
        pageSpecificThumbnail={mainImage.image.asset.fluid.src}
        pageSpecificThumbnailAlt={mainImage.image.alt}
        pathname={location.pathname}
      />
      <HeroWrapper>
        {mainImage.image && (
          <HeroImg
            fluid={mainImage.image.asset.fluid}
            style={{ width: '100%', height: 600 }}
            alt={mainImage.image.alt}
          />
        )}
        <HeroText>
          {(extendedTitle || title).split('<br />').map((_text) => (
            <h1>{_text}</h1>
          ))}
        </HeroText>
        {slug.current.includes('training') && viewportWidth >= 1200 && (
          <HeroIcons>
            <div>
              <img src={VideoSVG} alt="Video" />
              <span>Video</span>
            </div>
            <div>
              <img src={TrainingSVG} alt="Training" />
              <span>Training</span>
            </div>
            <div>
              <img src={StrategySVG} alt="Strategy" />
              <span>Strategy</span>
            </div>
            <div>
              <img src={LivestreamSVG} alt="Livestream" />
              <span>Live Stream</span>
            </div>
            <div />
          </HeroIcons>
        )}
      </HeroWrapper>
      <ContentWrapper>
        <ContentBody
          blockContent={_rawBody}
          closingLine={closingLine}
          openingLine={openingLine}
          vimeoVideo={vimeoVideo}
          slug={slug.current}
        />
        <ContentAside otherServices={otherServices.nodes} slug={slug.current} />
      </ContentWrapper>
      {slug.current.includes('training') && (
        <FooterImg
          fluid={trainingFooterImage.childImageSharp.fluid}
          style={{ width: '100%', height: 300 }}
          alt="Footer Image"
        />
      )}
    </Layout>
  );
};

export const HeroWrapper = styled.div`
  position: relative;
`;

const HeroLogo = styled.img`
  height: 100px;
  position: absolute;
  top: 6rem;
  left: 12px;
  @media screen and (min-width: 420px) {
    left: 24px;
  }
  @media screen and (min-width: 1176px) {
    height: 125px;
    left: 9%;
  }

  @media screen and (min-width: 1440px) {
    height: 150px;
    left: 8.125rem;
  }
`;

const HeroIcons = styled.div`
  background: linear-gradient(269.94deg, #e4097e 63.91%, #ffef00 100%);
  border-radius: 50px 0 0 50px;
  position: absolute;
  top: 6rem;
  right: 0;
  height: 100px;
  width: 30%;
  display: grid;
  gap: 0.25rem;
  grid-template-columns: repeat(5, 1fr);
  padding-left: 1rem;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    span {
      color: #ffffff;
      text-align: center;
      font-size: 0.875rem;
    }
    img {
      height: 50%;
    }
  }
`;

export const HeroText = styled.div`
  position: absolute;
  color: #fff;
  bottom: 3.05rem;
  max-width: 58.75rem;
  left: 12px;
  margin-right: 12px;

  @media screen and (min-width: 420px) {
    left: 24px;
    margin-right: 24px;
    bottom: 5.625rem;
  }

  h1 {
    font-size: 3rem;
    font-weight: 400;
    &:nth-child(2) {
      position: relative;
      display: inline-block;
      &:after {
        content: '';
        background: linear-gradient(269.94deg, #e4097e 63.91%, #ffef00 100%);
        height: 3px;
        bottom: -0.75rem;
        left: 0;
        width: 110%;
        max-width: 720px;
        position: absolute;
      }
    }
    @media screen and (min-width: 820px) {
      font-size: 4rem;
    }
  }

  h3 {
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
  }

  @media screen and (min-width: 1176px) {
    left: 9%;
  }

  @media screen and (min-width: 1440px) {
    left: 8.125rem;
  }
`;

export const MultiColouredBottomBorder = styled.div`
  background: linear-gradient(269.94deg, #e4097e 63.91%, #ffef00 100%);
  height: 0.188rem;
  margin: 0.063rem 0 0.75rem;
  width: calc(100% + 1rem);
`;

export const HeroImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  max-width: 1176px;
  position: relative;

  @media screen and (min-width: 420px) {
    padding: 0 24px;
  }

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 12px;
    margin: 0 auto;
  }

  @media screen and (min-width: 1224px) {
    padding: 0;
  }
`;

export const FooterImg = styled(Img)``;

export const query = graphql`
  query($slug: String!) {
    trainingFooterImage: file(relativePath: { eq: "Shared/Services/02_training_footer.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    service: sanityService(slug: { current: { eq: $slug } }) {
      title
      extendedTitle
      slug {
        current
      }
      mainImage {
        image {
          asset {
            fluid(maxWidth: 1440, maxHeight: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
        title
        alt
      }
      vimeoVideo {
        url
      }
      openingLine
      _rawBody
      closingLine
    }
    otherServices: allSanityService(filter: { slug: { current: { ne: $slug } } }) {
      nodes {
        id
        title
        extendedTitle
        mainImage {
          image {
            asset {
              fixed(width: 325, height: 198) {
                ...GatsbySanityImageFixed
              }
            }
          }
          title
          alt
        }
        slug {
          current
        }
      }
    }
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Service);
